import "./loader.css"

import React from "react"

export default function Loader() {
  return (
    <div className="loader">
      <div />
    </div>
  )
}
