import "./index.css"

import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import LandingReelVideoDsktp from "../videos/landingReel.mp4"
import LandingReelVideoMbl from "../videos/landingReelV.mp4"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Loader from "../components/loader/loader"

export default function IndexPage() {
  const { t } = useTranslation()
  const [videoRef, setVideoRef] = React.useState(null)

  // console.log(videoRef)   TO-DO APAÑAR EL LOADING DEL VIDEO EN EL LANDING. AHORA CARGA OK PERO EN RESIZE SE QUEDA UN MOMENTO EN BLANCO Y ES CACA

  const landingVideo = React.useRef(null)

  function playVideo() {
    landingVideo.current.play()
  }

  function handleKeyDown(ev) {
    // console.log(ev.code)
    if (ev.code === "Space") {
      landingVideo.current.play()
    }
  }

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerHeight > window.innerWidth
        ? setVideoRef(LandingReelVideoMbl)
        : setVideoRef(LandingReelVideoDsktp)
    })
  })

  React.useEffect(() => {
    window.innerHeight > window.innerWidth
      ? setVideoRef(LandingReelVideoMbl)
      : setVideoRef(LandingReelVideoDsktp)
  }, [])

  return (
    <Layout territory={"white"} hideFooter="true">
      <Seo title={t("Home")} />
      <div
        className="vignette"
        onClick={playVideo}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <Loader />
        <video
          autoPlay
          loop
          muted
          playsInline
          className="landingVideoBg"
          ref={landingVideo}
          src={videoRef}
          type="video/mp4"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
